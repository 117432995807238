import Vue from "vue";

// INFO - MS - Add any components that may be missing
const componentDeactivatable = [
  "v-input",
  "v-btn",
  "v-text-field",
  "v-textarea",
  "v-select",
  "v-list-item",
  "v-checkbox",
  "v-radio",
  "v-switch",
  "v-date-picker",
  "v-time-picker",
  "v-dialog",
  "v-menu",
  "v-tabs",
  "v-tab",
  "v-data-table",
  "v-autocomplete",
  "v-file-input",
  "v-slider",
];

Vue.directive("socio-permissions", (el, binding, vnode) => {
  const canEdit = vnode.componentInstance.$route.meta?.permissions?.edit;
  const isEditPermissionDefined = canEdit !== undefined;

  if (!isEditPermissionDefined) {
    console.warn(
      `Be careful, the "edit" permissions is not defined on the route with routeName "${vnode.componentInstance.$route.name}"`
    );
    return;
  }

  const component = componentDeactivatable.find((cls) =>
    el.classList.contains(cls)
  );
  const shouldDisableElement =
    !canEdit() || (binding.value?.additionalDisableClause ?? false);

  if (shouldDisableElement) {
    el.setAttribute("disabled", "true");
    el.classList.add(`${component}--disabled`);
    el.style.pointerEvents = "none";
    // In some case encountered, adding the --disabled class remove our theme--light and put theme--dark
    el.classList.replace("theme--dark", "theme--light");
  } else {
    el.removeAttribute("disabled");
    el.classList.remove(`${component}--disabled`);
    el.style.pointerEvents = "initial";
    if (binding.value?.dark) {
      el.classList.replace("theme--light", "theme--dark");
    }
  }
});
